import React from "react";
import { graphql, navigate } from "gatsby";

import { Wrapper } from "../components/style/global-styles";
import Box from "../components/box";
import styled from "styled-components";
import ImageBox from "../components/image-box";
import AnimatedText from "../components/animated-text";
import { hoverChange } from "../functions/util";

/**
 * page-template Component
 *
 * @param {Object} props
 * @param {Object} props.data
 */
export default function Template({
  data: {
    prismicPage: { data },
    allPrismicPost: { edges },
  },
}) {
  return (
    <>
      <WorkGrid>
        {edges.map(({ node }, i) => {
          return (
            <WorkGridItem
              key={i}
              onClick={() => navigate(node.url)}
              onMouseEnter={() => hoverChange("project")}
              onMouseLeave={() => hoverChange(null)}
            >
              <ImageBox fluid={node.data.marquee.fluid} />
              <TextBox>
                <AnimatedText
                  className="h5 sequel"
                  text={node.data.project_name}
                />
                {node.data.title && <h6>{node.data.date}</h6>}
              </TextBox>
            </WorkGridItem>
          );
        })}
      </WorkGrid>
    </>
  );
}

const WorkGrid = styled(Wrapper)`
  margin: 15vmax 0 8vw;
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  gap: 4vw;

  @media ${(props) => props.theme.device.tablet} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 2rem;

  h5,
  h6 {
    margin: 0;
  }

  @media ${(props) => props.theme.device.tablet} {
    margin: 0;
  }
`;

const WorkGridItem = styled(Box)`
  display: flex;
  flex-direction: column;
  /* gap: 2rem; */
  grid-column: span 2;

  @media (hover: hover) {
    &:hover {
      img {
        transform: scale3d(1.05, 1.05, 1.05);
      }
    }
  }

  .gatsby-image-wrapper {
    height: 50vw;
    margin-bottom: 2rem; // gap override

    @media ${(props) => props.theme.device.tablet} {
      height: 28vw;
    }
  }

  img {
    transition: transform ${(props) => props.theme.animation.duration[300].css}
        ${(props) => props.theme.animation.timingFunction.css},
      opacity ${(props) => props.theme.animation.duration[100].css}
        ${(props) => props.theme.animation.timingFunction.css} !important;
  }
`;

/**
 * pageQuery
 */
export const pageQuery = graphql`
  query work($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      uid
      data {
        title
      }
    }
    allPrismicPost(
      sort: { fields: data___date, order: DESC }
      filter: { data: { on_the_sly: { nin: true }, date: { gt: "1901" } } }
    ) {
      edges {
        node {
          uid
          url
          data {
            title
            project_name
            work_for {
              text
            }
            date(formatString: "YYYY")
            marquee {
              fluid(
                placeholderImgixParams: { blur: 1600, px: 1 }
                maxWidth: 1200
              ) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }
  }
`;
